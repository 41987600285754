<template>
    <v-container fluid>
    <v-row>
      <v-col class="pt-3 pb-2">
        <h1>Add New Daily Return Sheet</h1>
      </v-col>
    </v-row>
    <div>
      <v-row>
        <v-col class="col-3 pt-3 pb-2">
          <label>Date for the DRS</label>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                v-model="drs.work_date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-3 pt-3 pb-2">
          <v-select
            label="Chargehand"
            v-model="drs.daily_return_sheets_workers[0].crew_member.id"
            :items="crewMembers"
            item-value="id"
            item-text="name"></v-select>
        </v-col>
      </v-row>
      <div v-for="(worker, index) in drs.daily_return_sheets_workers" :key="`w_${index}`">
        <v-row v-if="index > 0">
          <v-col class="col-3 pt-3 pb-2">
            <v-select
              :label="`Worker ${index}`"
              v-model="worker.crew_member.id"
              :items="crewMembers"
              item-value="id"
              item-text="name"></v-select>
          </v-col>
        </v-row>
      </div>
      <v-row>
        <v-col class="col-9 pt-3 pb-2">
          <hr />
          <p class="pt-4">Health and Safety</p>
          <v-checkbox
            dense
            ripple="false"
            label="Tick to confirm all PPE was available and in good condition"
            v-model="drs.ppe_good_condition"></v-checkbox>
          <hr />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-3">
          <v-text-field v-model="drs.notes" label="Description of work"></v-text-field>
        </v-col>
      </v-row>
      <v-row class="pb-10">
        <v-col>
          <v-btn x-large @click="save">Save</v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'DrsCreate',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  watch: {
    date () {
      this.drs.work_date = this.formatDate(this.date);
    },
  },
  data() {
    return {
      drs: {
        id: 0,
        quotation_id: 0,
        job_no: '',daily_return_sheets_bonus_payments: [],
        daily_returns_sheets_material_usages: [],
        daily_return_sheets_workers: [
          { crew_member: {} },
          { crew_member: {} },
          { crew_member: {} },
          { crew_member: {} },
        ],
        quotation: {
          customer: {},
        },
      },
      confirmNumber: false,    
      crewMembers: [],
      times: [],
      menu: false,
      date: null,
      boolean: [
        { text: "No", value: 1 },
        { text: "Yes", value: 0 },
      ],
    };
  },
  methods: {
    formatDate (date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}-${month}-${year}`;
    },
    save() {
      const postData = {};
      postData.drs = this.drs;
      axios.post(`/dailyReturnSheets/createSimple.json?token=${this.token}`, postData)
        .then((response) => {
          const drs = response.data.drs.id;
          this.$router.push(`/drs/update/${drs}`);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    generateTimes() {
      let i = 0; 
      const times = [];
      let time = 0.25;
      while(i < 71) {
        times.push(time);
        time += 0.25;
        i += 1;
      }
      this.times = times;
    },
    getCrewMembers() {
      axios.get(`/crewMembers/getCrewForDrsEdit.json?token=${this.token}`)
        .then((response) => {
          this.crewMembers = response.data.crewMembers;
        });
    },
  },
  mounted() {
    this.generateTimes();
    this.getCrewMembers();
  },
};
</script>
